<template>

    <div>
        <div class="wrapper-registro cover-login" v-if="showpass">
            <div class="popup" >

                <ValidationObserver ref="form" v-slot="{failed,valid}">
                    <form class="paso row-between fww" @submit.prevent="validate" :valid="valid" autocomplete="off">
                        <h2 class="titulo left">Activar cuenta</h2>


                        <div class="contenedor-input">
                            <ValidationProvider :rules="'required|min:8'" v-slot="{ errors,ariaInput,ariaMsg,classes }"
                                vid="password">
                                <input autocomplete="off" type="password" name="pass" placeholder="Contraseña"
                                    v-model="data.password" :class="classes">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="contenedor-input">
                            <ValidationProvider :rules="'required|confirmed:password|min:8'"
                                v-slot="{ errors,ariaInput,ariaMsg,classes }">
                                <input autocomplete="off" type="password" name="repite-pass"
                                    placeholder="Repite tu contraseña" v-model="data.password_confirmation"
                                    :class="classes">
                                <span v-bind="ariaMsg" class="custom-error">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>



                        <div class="full-width row-center">
                            <button class="boton-azul" :loading="loading">Guardar</button>
                        </div>



                    </form>
                </ValidationObserver>
            </div>



        </div>
        <activar-perfil v-if="!showpass" :inactivar="true" @finish="showpass = true"></activar-perfil>
    </div>
</template>

<script>

    import { mapActions } from 'vuex';

    import router from '@/router';


    import activarperfil from '@/views/completa-tu-perfil';

    export default {
        name: 'set-password',

        mounted() {
            if (this.$route.query.token) {
                this.data.fake_login_token = this.$route.query.token;
            } else {
                router.push({
                    name: 'login'
                })
            }
        },

        data: () => ({
            data: {
                'password': '',
                'password_confirmation': '',
                'fake_login_token': ''
            },
            loading: false,
            showpass: true
        }),

        methods: {
            ...mapActions(['activarcuenta']),
            validate() {
                this.$refs.form.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        this.activarcuenta(this.data).then(r => {
                            this.loading = false;
                            if (r) {
                                // this.showpass = false;
                            }
                        })
                    }
                });
            },
        },
        components: {
            'activar-perfil': activarperfil
        }
    }
</script>

<style lang="scss" scoped>
    .cover-login .popup {
        max-width: 400px;
    }
</style>